import React from "react"

function Footer () {
  return (
    <footer>
            © {new Date().getFullYear()}, CelotehBahasa.com
    </footer>
  )
} 

export default Footer